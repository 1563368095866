import {
  FooterLayout,
  FooterMain,
  QuickLinks,
  Socials,
  StayConnectedSection,
  StayConnectedText,
  NewsLetterCon,
  InputLayout,
  JoinButton,
  InputErrorMsg,
  EmailReceived,
  CustomLoader,
  SubscribesError,
  StayConnectedLayout,
  StatusIndicator,
} from './index.styles';
import Icon from '../../../assets/images/Icon-landing.svg';
import Twitter from '../../../assets/images/twitter.svg';
import Instagram from '../../../assets/images/instagram.svg';
import GreenTick from '../../../assets/images/green-tick.svg';
import AppStoreDownload from '../../../assets/images/app-store-download.svg';
import { useRouter } from 'next/router';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { useState } from 'react';

const url = process.env.NEXT_PUBLIC_MAILCHIMP_URL;

const QuickLinksCom = () => {
  const router = useRouter();

  return (
    <QuickLinks>
      <p
        onClick={() =>
          router.push(
            '/legal/copyright' + (router.query.r ? `?r=${router.query.r}` : '')
          )
        }
      >
        2023 &copy; Togather
      </p>
      <p
        onClick={() =>
          router.push(
            '/legal/privacy' + (router.query.r ? `?r=${router.query.r}` : '')
          )
        }
      >
        Privacy Policy
      </p>
      <p
        onClick={() =>
          router.push(
            '/legal/terms' + (router.query.r ? `?r=${router.query.r}` : '')
          )
        }
      >
        Legal
      </p>
    </QuickLinks>
  );
};

const NewsLetterForm = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState('');
  const [inputActive, setInputActive] = useState(false);
  const [inputValid, setInputValid] = useState(true);

  const handleFormSubmit = () => {
    const emailRegex = /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,63})+$/;
    if (emailRegex.test(email)) {
      const isFormValidated = onValidated({ EMAIL: email });
      return email && email.indexOf('@') > -1 && isFormValidated;
    } else {
      setInputValid(false);
    }
    return null;
  };

  const handleInputKeyEvent = (event) => {
    // Number 13 is the "Enter" key on the keyboard
    if (event.keyCode === 13 && status !== 'sending') {
      event.preventDefault();
      handleFormSubmit();
    }
  };

  const getMessage = (message) => {
    if (!message) {
      return null;
    }
    const result = message?.split('-') ?? null;
    const formattedMessage = result?.[1]?.trim() ?? null;
    return formattedMessage ? formattedMessage : null;
  };

  return (
    <>
      <InputLayout inputValid={inputValid}>
        <input
          type="text"
          placeholder="Your email"
          value={email}
          onFocus={() => setInputActive(true)}
          onBlur={() => setInputActive(false)}
          onKeyUp={(event) => handleInputKeyEvent(event)}
          onChange={(e) => {
            setEmail(e.target.value);
            if (!inputValid) {
              setInputValid(true);
            }
          }}
        />
        {inputValid ? (
          <JoinButton
            type="button"
            onClick={handleFormSubmit}
            active={inputActive}
            onFocus={() => setInputActive(true)}
            disabled={status === 'sending'}
          >
            {status === 'sending' ? <CustomLoader /> : 'Join'}
          </JoinButton>
        ) : (
          <InputErrorMsg>Invalid email</InputErrorMsg>
        )}
      </InputLayout>
      {status === 'error' && (
        <SubscribesError>{getMessage(message)}</SubscribesError>
      )}
    </>
  );
};

const StatusInd = () => (
  <StatusIndicator>
    <iframe
      src="https://togather.instatus.com/embed-status/dark-sm"
      width="230"
      height="41"
      frameBorder="0"
      scrolling="no"
      style={{ border: 'none', borderRadius: '8px' }}
    ></iframe>
  </StatusIndicator>
);

const HomeFooter = ({ width, letterSectionShow = true }) => {
  const router = useRouter();

  return (
    <>
      {letterSectionShow && (
        <StayConnectedLayout>
          <StayConnectedSection>
            <StayConnectedText>
              <h3>Stay Connected</h3>
              <p>
                Join our newsletter to be the first to receive updates and news
                from Togather. :)
              </p>
            </StayConnectedText>
            <NewsLetterCon>
              <MailchimpSubscribe
                url={url}
                render={({ subscribe, status, message }) => (
                  <>
                    {status === 'success' ? (
                      <EmailReceived>
                        <GreenTick />
                        <p>Received! See you in our next newsletter. :)</p>
                      </EmailReceived>
                    ) : (
                      <NewsLetterForm
                        status={status}
                        message={message}
                        onValidated={(formData) => subscribe(formData)}
                      />
                    )}
                  </>
                )}
              />
            </NewsLetterCon>
          </StayConnectedSection>
        </StayConnectedLayout>
      )}
      <FooterLayout>
        <FooterMain>
          <div style={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
            <div>
              <div className="icon">
                <Icon />
              </div>
              {width > 680 && <QuickLinksCom />}
            </div>
            {width > 1024 && <StatusInd />}
          </div>
          <Socials>
            <a className="first" href="mailto:  support@gettogather.co">
              <p>Contact</p>
            </a>
            <p
              onClick={() => router.push('/signin')}
              style={{ cursor: 'pointer' }}
            >
              Sign In
            </p>
            <a
              href="https://twitter.com/gettogatherco"
              rel="noreferrer"
              target="_blank"
            >
              <Twitter />
            </a>
            <a
              href="https://www.instagram.com/gettogatherco/"
              rel="noreferrer"
              target="_blank"
            >
              <Instagram />
            </a>
            <a
              href="https://apps.apple.com/us/app/togather-church-app/id1538964007"
              rel="noreferrer"
              target="_blank"
            >
              <AppStoreDownload className="app-store" />
            </a>
          </Socials>
          {width <= 680 && <QuickLinksCom />}
          {width <= 1024 && <StatusInd />}
        </FooterMain>
      </FooterLayout>
    </>
  );
};

export default HomeFooter;
