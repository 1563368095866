import styled from 'styled-components';

export const Header = styled.div`
  max-width: 1420px;
  margin: 0 auto;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${({ fixHeader }) =>
    fixHeader ? '10px 16px !important' : '22px 32px'};

  background: rgba(37, 37, 39, 0.1);
  backdrop-filter: blur(4px);
  border-radius: 20px;
  height: ${({ fixHeader }) => (fixHeader ? '48px !important' : '84px')};
  transition: height 0.5s ease-in-out, padding 0.5s ease-in-out;

  svg {
    transition: height 0.5s ease-in-out, width 0.5s ease-in-out;
  }

  ${({ fixHeader }) => fixHeader && 'svg {width: 139px; height: 28.24px;}'}

  @media (max-width: 1024px) {
    padding: 18px 24px;
    height: 64px;
    svg {
      height: 28px;
      width: 140px;
    }
  }

  @media (max-width: 600px) {
    ${({ isMobileMenuOpen }) => isMobileMenuOpen && 'display: none;'}
    svg {
      width: 110px;
      height: 22px;
    }
  }
`;

export const HeaderBtnContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${({ fixHeader }) => (fixHeader ? '16px' : '40px')};

  transition: gap 0.5s ease-in-out;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const MobileMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    width: 24px;
    height: 18px;

    ${({ fill }) => (fill ? 'path {fill: #FFFFFF;}' : 'path {fill: #252527;}')}
  }
`;

export const PrimaryButton = styled.button`
  padding: ${({ fixHeader, fillColor }) =>
    fillColor
      ? fixHeader
        ? '7px 16px !important'
        : '9px 20px'
      : fixHeader
      ? '7px 0px !important'
      : '9px 0px'};
  border: none;
  border-radius: 58px;

  font-family: 'Satoshi', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: ${({ fixHeader }) => (fixHeader ? '15px !important' : '18px')};
  transition: font-size 0.5s ease-in-out, padding 0.5s ease-in-out;
  line-height: 120%;

  color: ${({ fillColor, lightMode }) =>
    fillColor
      ? lightMode
        ? '#2C2C3A'
        : '#FFFFFF'
      : lightMode
      ? '#FFFFFF'
      : '#2C2C3A'};
  background: ${({ fillColor, lightMode }) =>
    fillColor ? (lightMode ? '#FFFFFF' : '#252527') : 'transparent'};

  @media (max-width: 1024px) {
    padding: ${({ fillColor }) => (fillColor ? '7px 16px' : '7px 0px')};
    font-size: 15px;
  }

  @media (max-width: 600px) {
    padding: 8px 24px;

    font-size: 28px;
    line-height: 120%;

    color: #222224;
    border-color: #222224;
  }
`;

export const MobileLogo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    width: 28.87px;
    height: 27.9px;
    ${({ fill }) => (fill ? 'path {fill: #FFFFFF;}' : 'path {fill: #252527;}')}
  }
`;

export const MobileOverlay = styled.div`
  height: 100vh;
  background: #2c2c3a;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const FakeNav = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 20px 24px 0px;

  svg.logo {
    width: 139px;
    height: 28.24px;
  }
`;

export const TopButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  margin-top: 47px;

  button {
    font-family: 'Satoshi', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 120%;
    color: #ffffff;
    background: #2c2c3a;
  }
`;
