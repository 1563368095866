import styled from "styled-components";

export const FooterLayout = styled.div`
  background: #252527;
`;

export const FooterMain = styled.div`
  width: 1120px;
  margin: 0 auto;
  padding: 48.25px 0;
  bottom: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .icon {
    height: 28.31px;
    svg {
      width: 141px;
      height: 28.31px;
    }
  }

  @media (max-width: 1200px) {
    width: 100%;
  }

  @media (max-width: 1024px) {
    padding: 40px 32px;
    flex-wrap: wrap;
  }
  
  @media (max-width: 480px) {
    padding: 32px 38px;
    flex-direction: column;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
    }
  }
`;

export const QuickLinks = styled.div`
  display: flex;
  padding-top: 5.86px;
  gap: 15px;

  p {
    cursor: pointer;
    font-family: 'Satoshi', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;

    color: #A7A7BF;
  }

  @media (max-width: 1024px) {
    p {
      padding-right: 8px;
      padding-left: 8px;
      font-size: 13px;
      line-height: 16px;
    }
  }

  @media (max-width: 480px) {
    margin-top: 32px;
  }
`;

export const Socials = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;

  svg {
    height: 26px;
  }

  p {
    font-family: 'Satoshi', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 120%;
    color: #FFFFFF;
  }

  @media (max-width: 1024px) {
    gap: 24px;
    svg {
      Width: 32px;
      Height: 26px;
    }

    .app-store {
      width: 78px;
    }
  }

  @media (max-width: 680px) {
    margin-top: 20px;
    width: 190px;

    flex-wrap: wrap;
    gap: 12px 24px;

    .first {
      margin-left: 40px;
    }

    p {
      width: 40%;
    }
    a {
      p {
        width: 40%;
      }
    }
  }
`;

export const StayConnectedLayout = styled.div`
  background-image: url(./static/images/homepage/gradient.png);
  background-position: center;
  background-size: cover;

  @media (max-width: 1095px) {
    background-image: url(./static/images/homepage/gradient-ipad.png);
  }

  @media (max-width: 480px) {
    background-image: url(./static/images/homepage/stay-connected-phone-bg.png);
  }
`;

export const StayConnectedSection = styled.div`
  width: 1120px;
  margin: 0 auto;
  padding: 56px 0;
  display: flex;
  flex-direction: row;

  @media (max-width: 1024px) {
    margin: 0;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 56px 40px;
  }

  @media (max-width: 680px) {
    padding: 40px 16px;
  }
`;

export const StayConnectedText = styled.div`
  width: 64%;
  display: flex;
  flex-direction: column;
  color: #222224;
  color: #FFFFFF;

  h3 {
    font-family: 'Satoshi', sans-serif;
    margin-bottom: 8px;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 120%;
    text-transform: uppercase;
  }
  
  p {
    font-family: 'Satoshi', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
  }

  @media (max-width: 1024px) {
    width: 455px;
    margin-right: 0;
    margin-bottom: 24px;
    align-items: center;

    h3 {
      text-align: center;
    }

    p {
      text-align: center;
    }
  }

  @media (max-width: 680px) {
    width: 288px;
    h3 {
      font-size: 16px;
    }
    p {
      width: 227px;
      font-size: 15px;
    }
  }
`;

export const NewsLetterCon = styled.div`
  width: 41%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 1095px) {
    width: 455px;
  }

  @media (max-width: 480px) {
    width: 288px;
  }
`;

export const InputLayout = styled.div`
  display: flex;
  justify-content: ${({inputValid}) => inputValid ? 'space-between' : 'flex-start'};

  background: #FFFFFF;
  border: 2px solid;
  border-color: ${({inputValid}) => inputValid ? '#FFFFFF' : '#C62727'};
  border-radius: 30px;
  padding: 8px;

  filter: drop-shadow(0px 6px 12px rgba(0, 0, 0, 0.12));

  input[type="text"] {
    width: 70%;
    padding: 10px 16px 10px 10px;

    font-family: 'Satoshi', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #2C2C3A;

    ::placeholder {
      color: #7F7F82;
    }

    :-ms-input-placeholder {
      color: #7F7F82;
    }

    ::-ms-input-placeholder {
      color: #7F7F82;
    }
  }

  @media (max-width: 680px) {
    padding: 4px;

    input[type="text"] {
      font-size: 15px;
      line-height: 120%;
      padding: 0px 16px 0px 10px;
    }
  }
`;

export const JoinButton = styled.button`
  border-radius: 58px;
  background: ${({active}) => active ? 'linear-gradient(101.31deg, #631139 0%, #32055B 100%)' : '#A7A7BF'};

  padding: 12px 24px;

  font-family: 'Satoshi', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 120%;

  text-align: center;
  color: #FFFFFF;

  ${({active}) => active && 'box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.12);'}

  @media (max-width: 680px) {
    padding: 8px 14px;

    font-size: 12px;
    line-height: 120%;
  }
`;

export const InputErrorMsg = styled.p`
  font-family: 'Satoshi', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 110%;

  display: flex;
  align-items: center;

  color: #C62727;

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

export const EmailReceived = styled.div`
  display: flex;
  flex-direction: row;

  svg {
    margin-right: 12px;
  }

  p {
    font-family: 'Satoshi', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;

    color: #222224;
  }
`;

export const CustomLoader = styled.div`
  border: 3px solid transparent;
  border-radius: 50%;
  border-top: 3px solid #f3f3f3;
  border-left: 3px solid #f3f3f3;
  border-bottom: 3px solid #f3f3f3;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

export const SubscribesError = styled.p`
  padding: 8px;
  color: #C62727;
`;

export const StatusIndicator = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 1024px) {
    margin: 23px auto 0;
  }
  @media (max-width: 680px) {
    margin: 16px auto 0;
  }
`;