import { useRouter } from 'next/router';
import { useState } from 'react';
import {
  FakeNav,
  Header,
  HeaderBtnContainer,
  MobileLogo,
  MobileMenu,
  MobileOverlay,
  PrimaryButton,
  TopButtons,
} from './index.styles';
import Icon from '../../../assets/images/Icon.svg';
import LogoBlack from '../../../assets/images/Icon-black.svg';
import CrossIcon from '../../../assets/images/homepage/cross.svg';
import BurgurIcon from '../../../assets/images/homepage/burger.svg';
import PhoneLogoIcon from '../../../assets/images/homepage/phone-logo.svg';
import { Socials } from '../footer/index.styles';
import AppStoreDownload from '../../../assets/images/app-store-download.svg';
import Twitter from '../../../assets/images/twitter.svg';
import Instagram from '../../../assets/images/instagram.svg';

const HomeHeader = ({
  fixHeader,
  showModal,
  width,
  lightMode,
  offToLandingpage = false,
  commonQuestions = null,
  withBanner = false,
}) => {
  const router = useRouter();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const fixedHeaderStyles = {
    position: 'fixed',
    width: '100%',
    top: fixHeader ? '0px' : withBanner ? (width > 681 ? '48px' : '56px') : '0px',
    zIndex: '1000',
    background: 'transparent',
  };

  const getStartedClicked = () => {
    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', 'view_pricing');
    }
    router.push('/pricing' + (router.query.r ? `?r=${router.query.r}` : ''));
  };

  return (
    <div
      style={{
        ...(isMobileMenuOpen ? {} : { padding: '10px 10px 0px' }),
        ...fixedHeaderStyles,
      }}
    >
      <Header fixHeader={fixHeader} isMobileMenuOpen={isMobileMenuOpen}>
        <div
          style={{ cursor: 'pointer', display: 'flex' }}
          onClick={() =>
            offToLandingpage
              ? router.push(
                  '/' + (router.query.r ? `?r=${router.query.r}` : '')
                )
              : window.scrollTo(0, 0)
          }
        >
          {width <= 680 ? (
            <MobileLogo fill={lightMode}>
              <PhoneLogoIcon />
            </MobileLogo>
          ) : lightMode ? (
            <Icon />
          ) : (
            <LogoBlack />
          )}
        </div>
        {width > 600 ? (
          <HeaderBtnContainer fixHeader={fixHeader}>
            <PrimaryButton
              onClick={getStartedClicked}
              fixHeader={fixHeader}
              lightMode={lightMode}
              style={
                router.pathname === '/pricing'
                  ? {
                      pointerEvents: 'none',
                    }
                  : {}
              }
            >
              Pricing
              {router.pathname === '/pricing' && (
                <hr
                  style={{
                    width: fixHeader ? '49px' : '60px',
                    height: '3px',
                    border: 'none',
                    background: lightMode ? '#FFFFFF' : '#252527',
                    borderRadius: '2px',
                    marginTop: '4px',
                    transition: 'width 0.5s ease-in-out'
                  }}
                />
              )}
            </PrimaryButton>
            <PrimaryButton
              onClick={() => router.push('/our-story')}
              fixHeader={fixHeader} lightMode={lightMode}
              style={router.pathname === '/our-story' ? {
                pointerEvents: 'none',
              } : {}}
            >
              Our Story
              {router.pathname === '/our-story' &&
                <hr
                  style={{
                    width: fixHeader ? '68px' : '83px',
                    height: '3px',
                    border: 'none',
                    background: lightMode ? '#FFFFFF' : '#252527',
                    borderRadius: '2px',
                    marginTop: '4px',
                    transition: 'width 0.5s ease-in-out'
                  }}
                />
              }
            </PrimaryButton>
            <PrimaryButton
              onClick={getStartedClicked}
              fixHeader={fixHeader}
              lightMode={lightMode}
              fillColor
              style={
                router.pathname === '/pricing'
                  ? {
                      background:
                        'linear-gradient(0deg, rgba(44, 44, 58, 0.24), rgba(44, 44, 58, 0.24)), #FFFFFF',
                      color: '#252527',
                      pointerEvents: 'none',
                    }
                  : {}
              }
            >
              Get Started Risk Free
            </PrimaryButton>
            <PrimaryButton
              fixHeader={fixHeader}
              lightMode={lightMode}
              onClick={() => router.push('/signin')}
              style={fixHeader ? {} : {marginLeft: '-16px'}}
            >
              Sign In
            </PrimaryButton>
          </HeaderBtnContainer>
        ) : (
          <MobileMenu
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            isMobileMenuOpen={isMobileMenuOpen}
            fill={lightMode}
          >
            <BurgurIcon />
          </MobileMenu>
        )}
      </Header>
      {width <= 600 && isMobileMenuOpen && (
        <MobileOverlay>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <FakeNav>
              <Icon className="logo" />
              <CrossIcon
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              />
            </FakeNav>
            <TopButtons>
              <button
                style={{
                  fontWeight: 500,
                  ...(router.pathname === '/pricing' ? {pointerEvents: 'none',} : {})
                }}
                onClick={getStartedClicked}
              >
                Pricing
                {router.pathname === '/pricing' && (
                  <hr
                    style={{
                      width: '80px',
                      height: '3px',
                      border: 'none',
                      background: '#FFFFFF',
                      borderRadius: '2px',
                      marginTop: '4px',
                    }}
                  />
                )}
              </button>
              <button
                style={router.pathname === '/our-story' ? {
                  pointerEvents: 'none',
                } : {}}
                onClick={() => router.push('/our-story')}
              >
                Our Story
                {router.pathname === '/our-story' &&
                  <hr
                    style={{
                      width: '105px',
                      height: '3px',
                      border: 'none',
                      background: '#FFFFFF',
                      borderRadius: '2px',
                      marginTop: '4px',
                    }}
                  />
                }
              </button>
              <button
                style={{
                  fontWeight: 500,
                }}
              >
                <a
                  target="_blank"
                  href="http://resources.gettogather.co/"
                  rel="noopener noreferrer"
                >
                  Common Questions
                </a>
              </button>
            </TopButtons>
            <PrimaryButton
              fixHeader={false}
              lightMode={true}
              fillColor
              style={{
                margin: '72px auto 0px',
                width: '270px',
                fontSize: '22px',
                ...(router.pathname === '/pricing'
                  ? {
                      background:
                        'linear-gradient(0deg, rgba(44, 44, 58, 0.24), rgba(44, 44, 58, 0.24)), #FFFFFF',
                      color: '#252527',
                      pointerEvents: 'none',
                    }
                  : {}),
              }}
              onClick={getStartedClicked}
            >
              Get Started Risk Free
            </PrimaryButton>
            <button
              style={{
                fontFamily: "'Satoshi', sans-serif",
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '22px',
                lineHeight: '120%',
                color: '#FFFFFF',
                background: '#2C2C3A',
                marginTop: '24px',
              }}
              onClick={() => router.push('/signin')}
            >
              Sign In
            </button>
          </div>
          <Socials style={{ margin: '72px auto 120px', flexWrap: 'nowrap', width: 'fit-content' }}>
            <a href="mailto: contact@gettogather.co">
              <p
                style={{
                  fontSize: '15px',
                  lineHeight: '120%',
                }}
              >
                Contact
              </p>
            </a>
            <a
              href="https://twitter.com/gettogatherco"
              rel="noreferrer"
              target="_blank"
            >
              <Twitter />
            </a>
            <a
              href="https://www.instagram.com/gettogatherco/"
              rel="noreferrer"
              target="_blank"
            >
              <Instagram />
            </a>
            <a
              href="https://apps.apple.com/us/app/togather-church-app/id1538964007"
              rel="noreferrer"
              target="_blank"
            >
              <AppStoreDownload
                style={{
                  width: '81px',
                  height: '26px',
                }}
                className="app-store"
              />
            </a>
          </Socials>
        </MobileOverlay>
      )}
    </div>
  );
};

export default HomeHeader;
